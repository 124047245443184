<template>
    <div class="main" :style="{ backgroundImage: `url(${playerBag})` }">
        <div class="player_content">
            <!-- 导航 -->
            <page-head :src="require('@/assets/images/common/nav-back-black.svg')"  class="nav_back" />
            <!-- 头部 -->
            <div class="header">
                <people-header :list="peopleData" peopleType="playerEvalDetail" :title="route.query.title" />
                <div class="eval">
                    <div class="eval_num eval_avg">{{evalData.avg}}</div>
                    <div class="eval_num eval_max">{{evalData.max}}</div>
                    <div class="eval_num eval_min">{{evalData.min}}</div>
                </div>
            </div>
            <!-- 基本信息 -->
            <div v-if="!loading" class="content">
                <div class="week_bar">
                    <evalChart v-if="workData && workData.length" :list="workData"  @changeEval="changeEval"/>
                    <no-data v-else />
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { useRoute , useRouter} from "vue-router";
    import { defineComponent, onMounted, getCurrentInstance, toRefs, reactive, watch } from 'vue';
    export default defineComponent({
        setup() {
            const route = useRoute();
            const router = useRouter();
            const { proxy } = getCurrentInstance();
            const { playerBag, color } = proxy.$Config.UI_CONFIG
            const { id, position } = route.query
            const state = reactive({
                playerBag,
                color,
                weekBarRef: null,
                peopleData: {},
                playersReportList: [],//球员获取评估项
                templateTitleInfo: [],

                workDiagram: {},//雷达图各项数据
                workData: {},//柱状图各项数据
                barLin: 55,//柱状图显示个数
                barDate: '',//柱状图时间

                barIndex: 0,
                colorObj: [
                    {
                        name: '',
                        start: "#FFB23E",
                        end: "#FFD02B",
                        key: ""
                    },
                    {
                        name: '',
                        start: "#AD6BEA",
                        end: "#C186F2",
                        key: ""
                    },
                    {
                        name: '',
                        start: "#2979FF",
                        end: "#42A5F5",
                        key: ""
                    }, {
                        name: '',
                        start: "#34C955",
                        end: "#85C934",
                        key: ""
                    }, {
                        name: '',
                        start: "#E65045",
                        end: "#E98680",
                        key: ""
                    },
                    {
                        name: '',
                        start: "#979797",
                        end: "#B4B4BB",
                        key: ""
                    },
                ],
                loading: true,
                evalData:{}, //评估数据
            });
            watch(
                () => state.barIndex,
                (newVal) => {
                    console.log(newVal)
                    weekBarData(state.playersReportList[newVal]);
                }
            );
            // 公共数据集合
            const getCommonData = async () => {
                // // 球员位置
                // const params = {
                //     key: 'evaluationItem'
                // };
                // const { code, data } = await proxy.$server.getCommonData(params)
                // if (code === 200) {
                //     state.templateTitleInfo = data
                // }
            }
            // 获取球员数据
            const getUserData = async () => {
                const params = {
                    id, // 球员id
                    info: 'staffPersonal'
                }
                const { code, data } = await proxy.$server.getUserMsg(params);
                if (code === 200) {
                    data.position = proxy.$utils.getKeyValue('playerPositionList', position);
                    state.peopleData = data;
                }
            };

            // 获取图表数据
            const getWeekAllData = async () => {
                state.loading = true;
                const params = {
                    playerId: id,//球员id
                    start: proxy.$Config.currentYearFirstDay,
                    end: proxy.$moment().format('YYYY/MM/DD'),
                };
                const { code, data } = await proxy.$server.getPlayersReportWeekAll(params);
                state.loading = false;

                // console.log(data,'data');
                if (code === 200 && data.list.length > 0) {
                    state.evalData = data ;

                    state.playersReportList = data;
                    weekBarData(data.list);
                }
            };
            //柱状图
            const weekBarData = (data) => {
                state.workData = data;
                // console.log(state.workData);
            };
            const changeEval = (val) =>{
                // console.log(val,'val');
                let path = '/playerList/evalDetail-data';
                router.push({
                    path,
                    query: {
                        id: val,
                    }
                })

            };

            onMounted(() => {
                getCommonData();
                getUserData();
                getWeekAllData();
            });

            return {
                changeEval,
                route,
                ...toRefs(state),
            }
        },
    })
</script>

<style scoped lang="scss">
    .main {
        width: 100%;
        height: 100%;
        background-size: 100% auto;
        position: relative;
        box-sizing: border-box;
        padding: 60px 40px;
        .player_content{
            position: relative;
            border-radius: 20px;
            box-sizing: border-box;
            height:100%;
            width: 100%;
            background: url("../../assets/images/common/injury-head-bg.png") no-repeat left top;
            background-size: 100% 300px;
            background-color: #FFFFFF;
            /*返回按钮*/
            .nav_back{
                position: absolute;
                top: 32px;
                left: 32px;
                z-index: 3;
                padding: 0;
            }
            .header {
                position: relative;
                .eval {
                    position: absolute;
                    top: 129px;
                    right: 81px;
                    height: 131px;
                    display: flex;
                    .eval_num{
                        width: 240px;
                        height: 100%;
                        background: url("../../assets/images/eval/eval-avg.png") no-repeat left top;
                        background-size: 100% 100%;
                        margin-left: 32px;
                        font-family: akrobatBold;
                        font-size: 46px;
                        line-height: 57px;
                        text-align: right;
                        color: #FFFFFF;
                        box-sizing: border-box;
                        padding:20px 32px 0 0;
                    }
                    .eval_max{
                        background: url("../../assets/images/eval/eval-max.png") no-repeat left top;
                        background-size: 100% 100%;
                    }
                    .eval_min{
                        background: url("../../assets/images/eval/eval-min.png") no-repeat left top;
                        background-size: 100% 100%;
                    }
                }
            }
            .content {
                position: relative;
                background: rgba(255, 255, 255, 0.05);
                box-sizing: border-box;
                height: calc(100% - 440px);
                margin: 60px 80px 0;
                .date {
                    position: absolute;
                    top: 28px;
                    right: 32px;
                    width: 272px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 600;
                    font-size: 32px;
                    color: #fff;
                    opacity: 0.8;
                    .today {
                        text-align: center;
                        width: 132px;
                    }
                    div {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 46px;
                        height: 46px;
                        box-sizing: border-box;
                        border-radius: 8px;
                        cursor: pointer;
                        img {
                            width: 14.39px;
                        }
                    }
                    .prev {
                        background: rgba(255, 255, 255, 0.2);
                    }
                    .next {
                        background: rgba(255, 255, 255, 0.3);
                    }
                }
                .label {
                    position: absolute;
                    left: 30px;
                    top: 30px;
                    font-size: 20px;
                    font-family: akrobatRegular;
                    color: rgba(255, 255, 255, .8);

                    li {
                        display: flex;
                        align-items: center;
                        float: left;
                        margin-right: 14px;

                        i {
                            width: 20px;
                            height: 20px;
                            display: inline-block;
                            margin-right: 10px;
                            border-radius: 6px;
                        }

                        span {
                            font-family: akrobatRegular;
                        }
                    }
                }
                .week_bar {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: calc(100%);
                    width: 100%;
                }
            }
        }
    }
    .no-data{
        color: #81818A;
    }
</style>
